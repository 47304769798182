<!--
 * @Description: 房源营销 优惠券
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:49:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-23 16:14:42
-->
<template>
  <div v-loading="loading">
    <el-card class="box">
      <div class="header-title">可叠加优惠</div>
      <el-row>
        <el-card
          shadow="hover"
          class="card"
          v-for="item in activityList.filter((x) => x.is_multiple)"
          :key="item.id"
          @click="openEdit(item)"
        >
          <div class="info">
            <span class="name">{{ item.type_text }}</span>
            <span></span>
          </div>
          <div class="help-block">已设置房源数量{{ item.join_house_count }}/{{ item.house_count }}</div>
        </el-card>
      </el-row>
    </el-card>
    <el-card class="box">
      <div class="header-title">
        不可叠加优惠
        <small>下列优惠若房客同时符合多个，将享受优惠力度最大的优惠</small>
      </div>
      <el-row>
        <el-card
          shadow="hover"
          class="card"
          v-for="item in activityList.filter((x) => !x.is_multiple)"
          :key="item.id"
          @click="openEdit(item)"
        >
          <div class="info">
            <span class="name">{{ item.type_text }}</span>
            <span></span>
          </div>
          <div class="help-block">已设置房源数量{{ item.join_house_count }}/{{ item.house_count }}</div>
        </el-card>
      </el-row>
    </el-card>
  </div>
  <edit-coupon :type="current_type" @close="current_type=''" @success="loadActivity"></edit-coupon>
</template>

<script>
// import common_api from "@/http/common_api";
import EditCoupon from "./edit_coupon.vue";
export default {
  components: {
    EditCoupon,
  },
  data() {
    return {
      loading: false,
      // loading_dtl: false,
      // visibleDrawer: false,
      // saving: false,
      activityList: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //房源列表
      tableData: {
        counts: 0,
      },
      //已选择的房源列表
      selectionList: [],
      // form: {},
      // rules: {},
      //梯度项
      items: [],
      //会员等级
      gradeList: [],
      //当前编辑的type
      current_type: "",
    };
  },
  created() {
    this.loadActivity();
    // this.loadHouseData();
    // common_api.get_grade().then((res) => {
    //   if (res.code == 0) {
    //     this.gradeList = res.data;
    //   }
    // });
  },
  methods: {
    /**
     * 加载所有的活动数据
     */
    loadActivity() {
      this.loading = true;
      this.$http.get("/seller/v1/activity").then((res) => {
        if (res.code == 0) {
          this.activityList = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 打开编辑框
     */
    openEdit(item) {
      // console.log(item);
      this.current_type = item.type;
      // this.items = [];
      // this.selectionList = [];
      // this.form = {};
      // this.form = Object.assign({}, item);
      // this.visibleDrawer = true;
      // // console.log(this.form);
      // if (!item.is_general) {
      //   this.loadDtl();
      // }
    },
    // /**
    //  * 加载详情
    //  */
    // loadDtl() {
    //   this.loading_dtl = true;
    //   this.$http
    //     .get("/seller/v1/activity/joindtl?type=" + this.form.type)
    //     .then((res) => {
    //       if (res.code == 0) {
    //         this.dtl = res.data;
    //         if (res.data) {
    //           this.form = Object.assign(this.form, res.data);
    //           this.selectionList = res.data.houses;
    //           this.items = res.data.items;
    //           if (this.items == null || this.items.length <= 0) {
    //             this.addItem();
    //           }
    //         } else {
    //           this.addItem();
    //         }
    //       }
    //       this.loading_dtl = false;
    //     });
    // },
    // /**
    //  * 搜索数据
    //  */
    // onSearch() {
    //   this.query.pageIndex = 1;
    //   this.loadHouseData();
    // },
    // /**
    //  * 加载房源数据
    //  */
    // loadHouseData() {
    //   this.loading = true;
    //   this.$http.post("seller/v1/house", this.query).then((res) => {
    //     if (res.code == 0) {
    //       this.tableData = res.data;
    //     }
    //     this.loading = false;
    //   });
    // },
    // /**
    //  * 清除所有选择
    //  */
    // onClearSelection() {
    //   this.selectionList = [];
    //   this.$refs.houseTable.clearSelection();
    // },
    // /**
    //  * 分页页数更改
    //  */
    // pageSizeChange(val) {
    //   this.query.pageSize = val;
    //   this.loadHouseData();
    // },
    // /**
    //  * 分页页码更改
    //  */
    // pageCurrentChange(val) {
    //   this.query.pageIndex = val;
    //   this.loadHouseData();
    // },
    // /**
    //  * 选择发生变化
    //  */
    // selectionChange(nodes) {
    //   nodes.forEach((item) => {
    //     if (!this.selectionList.find((x) => x.id == item.id)) {
    //       this.selectionList.push(item);
    //     }
    //   });
    // },

    // /**
    //  * 禁用选择的时间
    //  */
    // disabledDate(date) {
    //   var now = new Date();
    //   now.setDate(now.getDate() - 1);
    //   if (date < now) return true;
    //   now.setFullYear(now.getFullYear() + 1);
    //   if (date > now) return true;
    // },

    // /**
    //  * 添加梯度
    //  */
    // addItem() {
    //   if (this.items.length < this.form.stairway_num) {
    //     this.items.push({
    //       days: 1,
    //       way: 0,
    //       decimal_discount: "4",
    //       decimal_amount: "0",
    //       start_hour: 8,
    //       end_hour: 22,
    //     });
    //   }
    // },
    // /**
    //  * 提交保存
    //  */
    // onSubmit() {
    //   this.$refs.form.validate((valid) => {
    //     if (valid) {
    //       this.saving = true;
    //       this.form.items = this.items;
    //       this.form.houses = this.selectionList;
    //       this.$http.post("seller/v1/activity/join", this.form).then((res) => {
    //         if (res.code == 0) {
    //           this.$message.success("保存成功");
    //           this.visibleDrawer = false;
    //           this.loadActivity();
    //         }
    //         this.saving = false;
    //       });
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.box .header-title {
  margin-bottom: 20px;
  font-size: 16px;
}

.box .header-title small {
  font-size: 12px;
  color: var(--text-tip-color);
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 240px;
  min-height: 80px;
  cursor: pointer;
}

.item_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
}

.col-del {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>

<style >
.el-form.act-form .el-alert__content {
  width: 100%;
}
</style>